var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pt-56" },
        [
          _c(
            "v-row",
            { staticClass: "pt-4" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    grow: "",
                    "background-color": "bg",
                    color: "primary"
                  },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.tabs, function(item) {
                  return _c("v-tab", { key: item.id }, [
                    _c("span", { staticClass: "subtitle-2" }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  ])
                }),
                1
              ),
              _c("br"),
              _vm.tabContentList.length > 0
                ? _vm._l(_vm.tabContentList, function(card) {
                    return _c(
                      "v-col",
                      {
                        key: card._id,
                        staticClass: "px-4 py-2",
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass:
                                  "card rounded bank-cards align-center"
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex flex-column h-100 pa-6 pr-0",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("v-img", {
                                          staticClass: "mt-2",
                                          attrs: {
                                            src: _vm.baseImgUrl + card.image,
                                            width: 46,
                                            height: 46,
                                            cover: ""
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c("div", { staticClass: "white--text" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getCardTitle(card)) +
                                          " "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex justify-end align-end h-100 pa-6 white--text pl-0",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .replaceBankCardNo(card.bank_no)
                                            .slice(
                                              _vm.replaceBankCardNo(
                                                card.bank_no
                                              ).length - 8,
                                              _vm.replaceBankCardNo(
                                                card.bank_no
                                              ).length
                                            )
                                        ) +
                                        " " +
                                        _vm._s(
                                          card.slug
                                            ? "( " + card.slug + " )"
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "card__close ma-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      { attrs: { icon: "" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "card1" },
                                            on: {
                                              click: function($event) {
                                                return _vm.showRemoveBankCardDialog(
                                                  card.bank_no
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" mdi-close ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.tabContentList.length < 3
                ? _c(
                    "v-col",
                    { staticClass: "px-4 py-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rounded",
                          class: [{ white: _vm.theme === "light" }],
                          style: {
                            border:
                              _vm.theme === "light"
                                ? "1px dashed rgba(0, 0, 0, 0.2) !important"
                                : "1px dashed rgba(255, 255, 255, 0.5) !important"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                { staticClass: "card rounded align-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__info text-right title--text"
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { text: "" },
                                          on: { click: _vm.addBankCard }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "rounded-circle primary",
                                              attrs: { color: "black" }
                                            },
                                            [_vm._v(" mdi-plus ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-3 subtitle-1 title--text"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.addCardText) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center subtitle-2 pt-0 title--text pb-2",
                  attrs: { cols: "12" }
                },
                [_c("span", [_vm._v(" " + _vm._s(_vm.cardLimitText) + " ")])]
              )
            ],
            1
          ),
          _c(
            "DialogTemplate3",
            { attrs: { dialog: _vm.dialog.status, width: 280, height: 200 } },
            [
              _vm.dialog.type === "baseInfo"
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center w-100 subtitle-1 py-2 white--text",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("myCenter.completeInfo")) + " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "text px-6 mt-6",
                        attrs: { slot: "text" },
                        slot: "text"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("myCenter.firstAddBankCard")) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "subtitle",
                        attrs: { slot: "btn", text: "" },
                        on: {
                          click: function($event) {
                            _vm.dialog.status = false
                          }
                        },
                        slot: "btn"
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          slot: "btn",
                          to: "/user/personal",
                          text: "",
                          color: "primary"
                        },
                        slot: "btn"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("global.confirmation")) + " "
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm.dialog.type === "removeBankCard"
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center w-100 subtitle-1 py-2 primary--text",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v(" - ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "text px-6 mt-6",
                        attrs: { slot: "text" },
                        slot: "text"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tab
                                ? _vm.$t("flashMessage.deleteUsdtCard")
                                : _vm.$t("flashMessage.confirmDeleteBankCard")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "subtitle",
                        attrs: { slot: "btn", text: "" },
                        on: {
                          click: function($event) {
                            _vm.dialog.status = false
                          }
                        },
                        slot: "btn"
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { slot: "btn", text: "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.deleteCard(_vm.form.bank_no)
                          }
                        },
                        slot: "btn"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("global.confirmation")) + " "
                        )
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }